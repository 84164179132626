<template>
    <section class="payment-record" @click.stop="pickerclick">
        <div class="top-bar bg-white" style="border-radius: 10px">
            <div style="width: 100%;">
                <el-form ref="formSearch" size="small" :model="formSearch" inline>
                    <div style="width: 100%;" class="flex justify-center">
                        <div style="width: 49.5%;">
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">账单搜索 :</span>
                                <el-select ref="keyWordCodeRef" size="small" v-model="formSearch.keyWordCode" placeholder="请选择"
                                           style="width: 22%;margin: 0 5px">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="订单号" :value="1"/>
                                    <el-option label="合同号" :value="2"/>
                                    <el-option label="公寓名" :value="3"/>
                                    <el-option label="租户名" :value="4"/>
                                    <el-option label="租户电话" :value="5"/>
                                </el-select>
                                <el-input v-model="formSearch.keyWord" style="width: 22%;margin: 0 5px;" size="small"
                                          placeholder="请输入内容"/>
                                <span style="margin: 0 5px;padding-left: 30px">收费类型 :</span>
                                <el-select ref="billTypeRef" size="small" v-model="formSearch.billType" style="width: 22%;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in billTypes" :key="index" :label="item.codeName"
                                               :value="item.code"/>
                                </el-select>
                            </div>
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">租户类型 :</span>
                                <el-select ref="leasorTypeRef" size="small" v-model="formSearch.leasorType" style="width: 22%;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="法人" value="法人"/>
                                    <el-option label="个人" value="个人"/>
                                </el-select>
                                <span style="margin: 0 5px;padding-left: 30px">住房类型 :</span>
                                <el-select ref="apartmentParentTypeRef" size="small" v-model="formSearch.apartmentParentType" placeholder="请选择"
                                           style="width: 22%;margin: 0 5px">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="保障性租赁住房" :value="600210"/>
                                    <el-option label="长租公寓" :value="600220"/>
                                </el-select>
                                <el-select ref="apartmentTypeRef" size="small" v-model="formSearch.apartmentType" placeholder="请选择"
                                           style="width: 22%;margin: 0 5px">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in houseTypes" :key="index" :label="item.codeName"
                                               :value="item.code"/>
                                </el-select>
                            </div>
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">缴费状态 :</span>
                                <!-- <el-select size="small" v-model="formSearch.billStatus" style="width: 22%;margin: 0 5px"
                                           placeholder="请选择">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in billStatusTypes" :key="index"
                                               :label="item.codeName" :value="item.code"/>
                                </el-select> -->
                                <el-select ref="billStatusRef" v-model="formSearch.billStatus" placeholder="请选择账单状态" style="width: 22%;margin: 0 5px" size="small">
                                    <el-option label="全部" :value="0"/>
                                    <el-option label="待处理" :value="600601"/>
                                    <el-option label="已缴费" :value="600602"/>
                                    <el-option label="未完成" :value="600605"/>
                                    <el-option label="已作废" :value="600604"/>
                                </el-select>
                                <span style="margin: 0 5px;padding-left: 30px">支付渠道 :</span>
                                <el-select ref="paymentChannelRef" size="small" v-model="formSearch.paymentChannel" placeholder="请选择"
                                           style="width: 22%;margin: 0 5px">
                                    <el-option label="全部" :value="0"/>
                                    <el-option v-for="(item,index) in paymentChannelTypes" :key="index"
                                               :label="item.codeName" :value="item.codeName"/>
                                </el-select>
                            </div>
                        </div>
                        <div style="width: 49.5%;border-left: 1px #A7A7A7 dashed;padding-left: 40px">
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">收款时间 :</span>
                                <el-date-picker ref="startAndEndDateRef"  @change="pickerclickitr"  v-model="startAndEndDate" type="daterange" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small" style="width: 40%;margin-right: 10px"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">所属区域 :</span>
                                <el-select ref="districtRef" v-model="formSearch.district" size="small" clearable filterable allow-create
                                           default-first-option placeholder="请选择" style="width: 120px;">
                                    <el-option v-for="(item,index) in options" :key="index" :label="item"
                                               :value="item"/>
                                </el-select>
                            </div>
                            <div style="margin: 20px 0;" class="flex align-center">
                                <span style="margin: 0 5px;">账单时间 :</span>
                                <el-date-picker ref="billStartAndEndDateRef" @change="pickerclickitr"  v-model="billStartAndEndDate" type="daterange" format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd" size="small" style="width: 40%;margin-right: 10px"
                                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"/>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;" class="flex align-center">
                        <div style="width: 49.5%;">
                            <div style="justify-content: right;margin: 20px 0;padding: 0 30px;" class="flex">
                                <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
                            </div>
                        </div>
                        <div style="width: 49.5%;">
                            <div style="margin: 20px 0;padding: 0 30px; justify-content: left" class="flex">
                                <el-button  size="small" @click="paybillRentalExport">导出</el-button>
                              <el-button  size="small"  :underline="false" @click="handleReset">重置</el-button>
<!--                                <span style="padding:0 15px;font-size: 18px;">-->
<!--                                    <el-link type="primary" :underline="false" style="color:#2C8BF7!important;"-->
<!--                                             @click="handleReset">重置查询条件</el-link>-->
<!--                                </span>-->
                              <!--陈守亮-2024-1-19-发票暂时隐藏-->
                              <el-button size="small"  type="primary" @click="taxrateset" v-if="getButtonMenu('SHUILVSHEZHI')">税率设置</el-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="getButtonMenu('BATCH_CANCLE_BILL_BUTTON')" >
                        <el-link type="primary" :underline="false" @click="batchWriteOff">批量核销</el-link>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="top-bar bg-white" style="border-radius: 10px">
            <r-e-table class="bg-white" ref="billTableRef" :dataRequest="getPaybillList" :columns="billTableColumn"
                       :query="formSearch" :height="540" :notAuto="true" show-summary :summary-method="getSummaries"
                       @selection-change="handleSelectionChange">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="look(row)">详情</span>
                          <span class="table-btn" v-if="row.billingStatus === 3" @click="billingStatuslook(row)">查看</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <layer-batch-write-off ref="layerBatchWriteOff" @clickCancel="handleSearch"/>
<!--      税率设置弹框-->
        <dialogPostTaxrateset  ref="dialogPostTaxratesetref"></dialogPostTaxrateset>
    </section>
</template>

<script>
import {dialogLookfprefapi, getPaybillList, paybillRentalExport} from "@/api/bill";
    import {billTableColumn} from "@/views/bill-management/rent-withdrawal/data";
    import {downloadByData} from "@/utils/hooks/download";
    import {timeFormat, numberFormat} from "@custom";
    import {buttonCookies} from "@storage/cookies";
    import {getAddressList} from "@/api/community-management";

    export default {
        name: "payment-record",
        data() {
            return {
                itr:true,
                value: null,
                startAndEndDate: [],
                billTableColumn,
                formSearch: {
                    keyWordCode: 0,
                    keyWord: "",
                    billType: 0,
                    leasorType: 0,
                    apartmentParentType: 0,
                    apartmentType: 0,
                    billStatus: 0,
                    paymentChannel: 0,
                    paidStartDate: "",
                    paidEndDate: "",
                    billStartDate: "",
                    billEndDate: "",
                    district: null,
                },
                billTypes: [],
                houseTypes: [],
                billStatusTypes: [],
                paymentChannelTypes: [],
                billStartAndEndDate: [],
                multipleSelection: [],
                options: []
            };
        },
        components: {
            layerBatchWriteOff: () => import("./components/layer-batch-write-off"),
          dialogPostTaxrateset: () => import("./components/dialog-post-taxrateset"),
        },
      deactivated(){
        // alert('离开')
        // 2024-7-10-陈守亮处理切换页面关闭组件
        this.pickerclick()
      },
        methods: {
            getPaybillList(params) {
                const formSearch = this.formSearch;
                const paramsData = {...params, ...formSearch}
                return getPaybillList(paramsData);
            },
            handleSearch() {
                this.$refs["billTableRef"].getTableData();
            },
            handleReset() {
                this.$refs["formSearch"].resetFields();
                this.formSearch = {
                    keyWordCode: 0,
                    keyWord: "",
                    billType: 0,
                    leasorType: 0,
                    apartmentParentType: 0,
                    apartmentType: 0,
                    billStatus: 0,
                    paymentChannel: 0,
                    paidStartDate: "",
                    paidEndDate: "",
                    billStartDate: "",
                    billEndDate: "",
                    district: null,
                };
                //billStartDate  billEndDate
                this.startAndEndDate = null;
                this.billStartAndEndDate = null;
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            look(data) {
                this.$router.push({name: "bill-details", params: {data}});
            },
            paybillRentalExport() {
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                const {formSearch} = this;
                const page = this.$refs["billTableRef"].pNumber;
                const pageSize = this.$refs["billTableRef"].pSize;
                const paramsData = {...formSearch, page, pageSize}
                paybillRentalExport(paramsData).then(res => {
                    downloadByData({data: res, filename: `账单管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                }).finally(() => loading.close());
            },
            getSummaries(param) {
                const {columns, data} = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index === 1) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0); 
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';
                });
                return sums;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            batchWriteOff() {
                let uuids = this.multipleSelection.map(({uuid}) => uuid);
                this.$refs["layerBatchWriteOff"].openDialog({uuids: uuids.join(";")});
            },
          // //获取按钮权限数组
            getButtonMenu(menu) {
                return JSON.parse(buttonCookies.get()).map(({url}) => url).some(v => v === menu);
            },
          // 2023-12-29-陈守亮-税率设置弹框按钮
          taxrateset(){
            this.$refs.dialogPostTaxratesetref.paybillapihuixian()
            this.$refs.dialogPostTaxratesetref.addDialogVisible = true
          },
          // getButtonMenu(menu) {
          //   return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
          // },
          // 查看发票收据
          billingStatuslook(row){
            dialogLookfprefapi({uuid:row.uuid}).then(res => {
              let baseurl =res.msg
              window.open(baseurl, "_blank");

            })
          },
          // 2024-4-18-新增点击空白关闭组件
          pickerclick(e){
            console.log( this.itr)
            this.itr = !this.itr;
            if(this.itr){
              this.$refs['startAndEndDateRef'].hidePicker();
              this.$refs['billStartAndEndDateRef'].hidePicker();
            }
            this.$refs.keyWordCodeRef.blur()
            this.$refs.billTypeRef.blur()
            this.$refs.leasorTypeRef.blur()
            this.$refs.apartmentParentTypeRef.blur()
            this.$refs.apartmentTypeRef.blur()
            this.$refs.billStatusRef.blur()
            this.$refs.paymentChannelRef.blur()
            this.$refs.districtRef.blur()
            console.log(this.itr)
          },
          pickerclickitr(){
            this.itr = true;
            console.log(this.itr)
          },
        },
        watch: {
            startAndEndDate(value) {
                if (value && value.length !== 0) {
                    let [paidStartDate, paidEndDate] = value;
                    this.formSearch.paidStartDate = paidStartDate + " 00:00:00";
                    this.formSearch.paidEndDate = paidEndDate + " 23:59:59";
                } else {
                    this.formSearch.paidStartDate = null;
                    this.formSearch.paidEndDate = null;
                }
            },
            async 'formSearch.apartmentParentType'(value) {
                if (value !== 0) {
                    this.formSearch.apartmentType = 0;
                    this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
                } else {
                    this.formSearch.apartmentType = 0;
                    this.houseTypes = [];
                }
            },
            billStartAndEndDate(value) {
                if (value && value.length !== 0) {
                    let [billStartDate, billEndDate] = value;
                    this.formSearch.billStartDate = billStartDate + " 00:00:00";
                    this.formSearch.billEndDate = billEndDate + " 23:59:59";
                } else {
                    this.formSearch.billStartDate = null;
                    this.formSearch.billEndDate = null;
                }
            },

        },
        async mounted() {
            this.billStatusTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600600);
            this.paymentChannelTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500100);
            this.billTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500500);
            let res = await getAddressList();
            this.options = res.list;

            const {params: {billType, billStatus, startAndEndDate, data}} = this.$route;
            if (billType) this.formSearch.billType = billType;
            if (billStatus) this.formSearch.billStatus = billStatus;
            if (startAndEndDate && startAndEndDate.length !== 0) {
                this.billStartAndEndDate = startAndEndDate;
                this.formSearch.billStartDate = startAndEndDate[0] + " 00:00:00";
                this.formSearch.billEndDate = startAndEndDate[1] + " 23:59:59";
            }
            if (data) {
                const {stateCode, type, startDate, endDate, paymentChannel, paidDate, keyWordCode, keyWord, month, houseType, amountType} = data;
                if (type) this.formSearch.billType = parseInt(type);
                if (stateCode) this.formSearch.billStatus = stateCode;
                if (paymentChannel) this.formSearch.paymentChannel = paymentChannel;
                if (paidDate) this.startAndEndDate = [timeFormat(new Date(paidDate)), timeFormat(new Date(paidDate))];
                if (startDate && endDate) this.billStartAndEndDate = [timeFormat(new Date(startDate)), timeFormat(new Date(endDate))];
                if (keyWordCode) this.formSearch.keyWordCode = keyWordCode;
                if (keyWord) this.formSearch.keyWord = keyWord;
                if (month) this.formSearch.month = month;
                if (houseType) this.formSearch.houseType = houseType;
                if (amountType >= 0) this.formSearch.amountType = amountType;
            }
            this.handleSearch();
        },
    }
</script>

<style lang="scss" scoped>
    .payment-record {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>
<style>
    .el-date-editor .el-range-separator {
        width: 10% !important;
    }
</style>